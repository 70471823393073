body {
  margin: 0;
}
/* #root {
  max-width: 100vw;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
} */

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #099dfd);
}

.card {
  padding: 2em;
  display: flex;
  justify-content: center;
}

.read-the-docs {
  color: #888;
}

#channelName {
  display: block;
  padding: 15px;
  margin: 0 0 25px;
  width: 50vw;
  max-width: 500px;
}

.card button:hover {
  border-color: #099dfd;
  background-color: #099dfd;
}

#controlsToolbar {
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 99;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #00e2ed;
  padding: 10px 0;
  transition: 0.3s ease-in-out;
}
#mediaControls .btn {
  background: #333;
  border: #fff 1px solid;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  color: #ddd;
  padding: 10px 25px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
#mediaControls .btn,
.sideBtns ul li button {
  background: #111;
  border: #ddd 1px solid;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #ddd;
  padding: 0;
  margin: 0 5px;
  width: 50px;
  height: 50px;
  font-size: 21px;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
#endConnection {
  background: red !important;
  border: red 1px solid !important;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  width: 50px;
  height: 50px;
  margin: 0 5px;
}
#mediaControls button {
  background: transparent;
  padding: 0;
  border: transparent;
}
#mediaControls button div {
  margin: 0;
  padding: 0;
}
#mediaControls button div button {
  margin: 0 5px;
  line-height: 50px;
  background: #333;
  border: #ddd 1px solid;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  width: 50px;
  height: 50px;
}

#mediaControls .btn:hover,
#mediaControls button div button:hover,
.sideBtns ul li button:hover {
  border-color: #9875f6 !important;
  background: #9875f6 !important;
  color: #ffffff;
}

#endConnection {
  border-color: red;
  opacity: 50%;
}

#endConnection:hover {
  background-color: red;
  border-color: white;
  opacity: 100%;
}

.localVideo {
  width: 100%;
  /* height: calc(100vh - 81px); */
  height: calc(100vh - 40px);
  /* overflow-y: scroll; */
  position: relative;
  padding-left: 0;
  max-width: 1200px;
  margin: 20px auto;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
/* .videoApp:hover .localVideo {height: calc(100vh - 120px);}
.videoApp:hover  #controlsToolbar {bottom: 0;} */
.livestreem {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  max-width: 100%;
}
.livestreem .mywon {
  position: absolute !important;
  width: 200px !important;
  right: 21px;
  bottom: 70px;
  height: 200px !important;
  border-radius: 6px;
  border: #00e2ed 3px solid;
}
.mywon {
  height: calc(100vh - 110px) !important;
  margin-top: 0;
  border-radius: 30px;
  border: #333 1px solid;
}
.livestreem #remoteVideoGrid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* align-items: center; */
  width: 200px;
  position: relative;
  right: 0;
  background: #05071a;
  padding: 30px;
}
.remote-video-container {
  right: 30px;
  /* position: relative; */
  width: 25vw;
  /* aspect-ratio: 4/3; */
  /* max-width: 720px; */
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  border: #9875f6 1px solid;
  height: 100px;
  /* top: 0;
  /* overflow-y: auto; */
  margin: 15px 0 0;
}
.sharingScreen {
  width: calc(100% - 250px);
  position: fixed;
  left: 0;
  top: 0;
  height: calc(100vh - 82px);
  max-width: 100%;
  margin-top: 0;
}
.videoApp {
  display: flex;
  justify-content: flex-end;
  background: #0c0a0c;
  padding: 0;
  height: 100vh;
}
.sideBtns {
  position: absolute;
  right: 5%;
  top: 0;
}
.sideBtns ul {
  display: flex;
  margin: 10px 0;
}
.sideBtns ul button {
  cursor: pointer;
}
.watching {
  position: absolute;
  right: 30px;
  top: 0;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
  padding: 4px 8px;
  border-radius: 3px;
}

.live-icon {
  display: inline-block;
  position: absolute;
  height: 30px;
  top: 9px;
  left: 40px;
  background-color: red;
  width: 10px;
  height: 10px;
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
}
.live-icon:before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: live 1s ease-in-out infinite;
  z-index: -1;
}

@-webkit-keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}
.btn_live {
  left: 0px;
  display: block;
  font-weight: 700;
  position: absolute;
  padding: 4px 18px 6px;
  /* background-color: rgba(255, 255, 255, 0.9); */
  text-decoration: none;
  color: #ffffff;
  /* border-radius: 50px; */
  /* border: 1px solid white; */
  /* box-shadow: 0px 2px 0px #02679e; */
  transition: all 0.2s;
  z-index: 1;
  outline: none;
  -webkit-tap-highlight-color: aliceblue;
  top: 20px;
  text-transform: uppercase;
}

.btn_live:hover > span:before {
  -webkit-animation: none;
  animation: none;
}
/* .btn_live:active {
  transform: translateY(0px);
  padding: calc(13px * 0.95) calc(25px * 0.95);
  box-shadow: inset 0px 2px 1px #02679e;
} */

.btn_live {
  padding-right: 36px;
}
